.form-control{
  color: #BEBEBE!important;
  background-color: #252525!important;
  border-color: #303030!important;

  &:focus {
    color: #BEBEBE!important;
    background-color: #303030!important;
    border-color: #353535!important;
    box-shadow: none!important;
  }
}