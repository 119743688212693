.download-img{
  height: 50px;
  margin-right: 10px;
}

@media (max-width: 1053px) {
  .uevnt-downloads{
    display: block;

    .download-img{
      display: block;
      margin: 1rem auto;
    }
  }
}