.uevnt-home-logo{
  width: 280px;
}

.uevnt-home-description{
  font-size: 1.25rem;
}

.uevnt-home-carousel{
  width: 420px;
}

@media (max-width: 1520px) {
  .uevnt-home-title {
    font-size: 32px!important;
  }
  .uevnt-home-description{
    font-size: 18px!important;
  }
}

@media (max-width: 1260px) {
  .uevnt-home-logo{
    width: 240px;
  }
  .uevnt-home-title {
    font-size: 26px!important;
  }
  .uevnt-home-description{
    font-size: 16px!important;
  }
  .uevnt-home-carousel{
    width: 360px;
  }
}

@media (max-width: 1053px) {
  .uevnt-home-container{
    flex-direction: column;

    .w-50{
      width: 100% !important;
    }
  }

  .uevnt-home-title {
    text-align: center;
  }
  .uevnt-home-description{
    text-align: center;
  }
  .uevnt-home-carousel{
    width: 360px;
  }
}